import { OutlineButton, SubmitButton } from '@netspresso/components';
import { ADVANCED_COMPRESSION, isEmpty } from '@netspresso/shared';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { BASE_MODEL, COMPRESSION_METHOD, COMPRESSION_TYPE, LAYERS } from '../../../../../../constants';
import { useCompressFormContext } from '../../../../../../context';
import { CurrentSteps, CURRENT_STEPS } from '../../../../../../lib';

type ButtonGroupsProps = {
  currentStep: CurrentSteps;
  handleClickBack: VoidFunction;
};

export const ButtonGroups: React.FC<ButtonGroupsProps> = ({ currentStep, handleClickBack }) => {
  const {
    compressForm: { setValue, control, getValues, watch },
  } = useCompressFormContext();
  const watched = watch();
  const { isDirty, errors, isValid } = useFormState({ control });

  const isAdvancedSettingsClean = () => {
    if (!watched[LAYERS]) {
      return false;
    }

    const checkedLayers = watched[LAYERS].filter((layer) => layer.use);

    return (
      watched[COMPRESSION_TYPE] === ADVANCED_COMPRESSION &&
      currentStep === CURRENT_STEPS.SECOND &&
      (checkedLayers.length === 0 || checkedLayers.filter((layer) => layer.values[0] === '').length > 0)
    );
  };

  // use가 true인게 하나라도 있어야 하는데, true인 건 ''면 안된다.
  // console.log('errors :', errors);

  const isDisabled = () => {
    console.log('isDirty :', isDirty);
    console.log('errors :', errors);
    console.log('isValid :', isValid);
    console.log('watched[COMPRESSION_METHOD] :', watched[COMPRESSION_METHOD]);
    console.log('watched[BASE_MODEL] :', watched[BASE_MODEL]);
    console.log('currentStep :', currentStep);
    console.log('isAdvancedSettingsClean :', isAdvancedSettingsClean());

    return (
      !isDirty ||
      !isEmpty(errors) ||
      !isValid ||
      !watched[COMPRESSION_METHOD] ||
      !watched[BASE_MODEL] ||
      currentStep === CURRENT_STEPS.COMPLETED ||
      isAdvancedSettingsClean()
    );
  };

  const handleClickClearInput: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const layers = getValues(LAYERS);

    if (!layers || layers.length === 0) {
      return;
    }

    setValue(
      LAYERS,
      layers.map((layer) => ({
        ...layer,
        values: [...layer.values].fill(''),
        use: false,
      })),
      { shouldValidate: true }
    );
  };

  return (
    <>
      {currentStep === CURRENT_STEPS.SECOND && (
        <>
          <OutlineButton className="mr-3" onClick={handleClickBack} type="button">
            Back
          </OutlineButton>
          <OutlineButton className="mr-3" onClick={handleClickClearInput}>
            Clear Inputs
          </OutlineButton>
        </>
      )}
      <SubmitButton disabled={isDisabled()}>
        {currentStep === CURRENT_STEPS.FIRST ? 'Select Method' : 'Compress'}
      </SubmitButton>
    </>
  );
};
