import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { CompressDefaultValues, CompressionScheme, CompressModelType } from '../schemes';
// import { CompressDefaultValues, CompressScheme, CompressType } from '../schemes';

export const CompressFormContext = createContext<{ compressForm: UseFormReturn<CompressModelType> } | null>(null);

export const CompressFormContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const compressForm = useForm({
    defaultValues: CompressDefaultValues,
    resolver: yupResolver(CompressionScheme),
    mode: 'onChange',
  });

  return <CompressFormContext.Provider value={{ compressForm }}>{children}</CompressFormContext.Provider>;
};

export const useCompressFormContext = (): { compressForm: UseFormReturn<CompressModelType> } => {
  const context = useContext(CompressFormContext);

  if (!context) {
    throw new Error('useCompressFormContext must be used within a CompressFormContextProvider');
  }

  return context;
};
